<script>
import DefaultMixin from '../mixin'
import Arrematantes from '@/components/arrematante/components/ListaArrematantes.vue'
import Leiloeiro from "components/leiloes/components/tela-leiloeiro/Leiloeiro.vue";
import tableColumnsCache from "@/utils/tableColumnsCache";

let listName = 'fenaju.leiloeiros.lista'
let listStorage = tableColumnsCache(listName, [
  {
    name: 'photo',
    required: true,
    label: 'photo',
    align: 'left',
    field: 'photo',
    sortable: false,
    active: true
  },
  {
    name: 'nome',
    required: true,
    label: 'Nome',
    align: 'left',
    field: 'nome',
    sortable: true,
    active: true
  },
  {name: 'id', label: 'ID', field: 'id', align: 'left', sortable: true, active: true},
  {
    name: 'apelido',
    required: true,
    label: 'Matrícula',
    align: 'left',
    field: 'apelido',
    sortable: true,
    active: false
  },
  {
    name: 'documento',
    required: false,
    label: 'Documento',
    align: 'left',
    field: 'documento',
    sortable: false,
    active: true
  },
  {
    name: 'dataCadastro',
    required: false,
    label: 'Data Cadastro',
    align: 'left',
    field: 'dataCadastro',
    sortable: true,
    active: true
  },
  {
    name: 'compras',
    required: false,
    label: 'Domínios',
    align: 'left',
    field: 'compras',
    sortable: true,
    active: false
  },
  {
    name: 'lances',
    required: false,
    label: 'Domínios Expirados',
    align: 'left',
    field: 'lances',
    sortable: true,
    active: false
  },
  {
    name: 'status',
    required: true,
    label: 'Situação',
    align: 'left',
    field: 'status',
    sortable: false,
    active: true
  }
], 1)

export default {
  name: 'FenajuLeiloeiros',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    Arrematantes
  },
  data() {
    return {
      listName: listName,
      listStorage: listStorage,
      menuActive: 'leiloeiros'
    }
  },
  computed: {
    Leiloeiro() {
      return Leiloeiro
    }
  },
  beforeMount () {
  },
  watch: {
  },
  mounted() {
    document.body.classList.add('console-clientes-dashboard')
  },
  destroyed() {
    document.body.classList.remove('console-clientes-dashboard')
  },
  methods: {
  },
  meta: {
    title: 'Console',
    name: 'Console'
  }
}
</script>

<template>
    <div class="console-dashboard">
      <div class="erp-list-task leiloeiros-list">
        <arrematantes title="Leiloeiro" :list-storage-prop="listStorage" :list-storage-name="listName" />
      </div>
      <router-view />
    </div>
</template>

<style lang="stylus">
.console-dash-menu.maxWidth {
  max-width 1440px
}
</style>
